<template>
  <div class="styleguide-main">
    <div class="container" >
      <TheButton type="primary" icon="lock" iconPos="left">Primary button</TheButton>
      <TheButton type="secondary">Secondary button</TheButton>
      <TheButton type="danger">Danger button</TheButton>
      <TheButton type="success">Success button</TheButton>
      <TheButton type="info">Info button</TheButton>
      <TheButton type="transparent">Transparent button</TheButton>
      <TheButton type="primary-alt">Primary alt button</TheButton>
      <TheButton type="secondary-alt">Secondary alt button</TheButton>
    </div>
    <div class="container" >
      <TheButton type="primary" icon="arrow-right" rounded iconPos="right">Primary button</TheButton>
      <TheButton type="secondary" icon="arrow-left">Secondary button</TheButton>
      <TheButton type="danger" rounded circle icon="close"></TheButton>
      <TheButton type="success" icon="checkmark"></TheButton>
      <TheButton type="primary" small rounded icon="caret-down" iconPos="right">3</TheButton>
      <TheButton type="info" rounded mini>1</TheButton>
      <div class="container dark">
        <TheButton type="primary-alt" circle icon="lock" small></TheButton>
        <TheButton type="primary-alt">Primary alt button</TheButton>
      </div>
        <TheButton type="secondary-alt" circle>&lt;</TheButton>
    </div>
  </div>
</template>

<script>
import TheButton from '../components/TheButton.vue'

export default {
  name: 'Styleguide',
  components: {
    TheButton,
  },
}
</script>
